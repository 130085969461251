import React from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { Marginer } from "../components/marginer";
import { deviceSize } from "../components/responsive";
import TopSectionBackgroundImg from "../img/12.jpeg";
import TheBestSpecialistsImg from "../img/logo-rem.png";
import { useState } from 'react'
import a2 from '../img/facebook.png';
import a3 from '../img/whatsapp.png';
import a6 from '../img/amksoanim.png';


import './topSection.css';

const TopSectionContainer = styled.div`
  width: auto;
  height: auto;
  background: url(${TopSectionBackgroundImg}) no-repeat;
  background-position: 0px -150px;
  background-size: cover;
  text-align: center;

  
  @media only Screen and (max-width: 40em) {
    height: 530px;

    &:hover {
      transform: none;
    }
    &:focus {
      transform: none;
    }
  }
`;

const BackgroundFilter = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(38, 70, 83, 0.9);
  display: flex;
  flex-direction: column;
`;

const TopSectionInnerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;




const SloganText2 = styled.h3`
align-items: center;
  margin-right: 0;
  line-height: 1.4;
  color: #fff;
  font-weight: 500;
  font-size: 25px;


  @media only Screen and (max-width: 40em) {
    &:hover {
      transform: none;
    }
    &:focus {
      transform: none;
    }
  }
`;







export function TopSection3(props) {




  const { children } = props;

  const isMobile = useMediaQuery({ maxWidth: deviceSize.mobile });

  return (
    <TopSectionContainer style={{textAlign:"center", alignItems:"center"}}>

      <BackgroundFilter>
        
<SloganText2>

<div id="con" className="top"  style={{textAlign:"center" ,fontSize:"25px"}} className="contact-item">

  <p>

    <span>
      <i style={{textAlign:"center" ,fontSize:"7px"}} ></i> כתובת-
    </span>
    מבצע הדקל 14 פתח תקווה              </p>
</div>
<div style={{textAlign:"center" ,fontSize:"25px"}} className='contact-item'>
  <p>
    <span>
      <i className='fa fa-phone'></i> טלפון
    </span>{' '}
    050-6978865              </p>
</div>
<div style={{textAlign:"center" ,fontSize:"25px"}} className='contact-item'>
  <p>
    <span>
      <i className='fa fa-envelope-o'></i> 
    </span>{' '}

s.g.one2021@gmail.com

<h3 style={{textAlign:"center", alignItems:"center"}} > ימים ושעות פעילות המשרד</h3>
    <h3 > _________________</h3>

    <p >יום א'- 8:00 עד 18:30</p>
    <p>יום ב'- 8:00 עד 18:30</p>
    <p>יום ג'- 8:00 עד 18:30</p>
    <p>יום ד'- 8:00 עד 18:30</p>
    <p>יום ה'- 8:00 עד 18:30</p>
    <p>יום ו'- 8:00 עד 13:00</p>

  </p>
             

</div>







</SloganText2 >

        {children}
        <TopSectionInnerContainer>






<br/><br/>


        </TopSectionInnerContainer>

<br/><br/>

      </BackgroundFilter>
      
    </TopSectionContainer>
  );
}
