import React from 'react';
import ai from './img/AODG26844.png';
import ai2 from './img/2.gif';
import Typist from 'react-typist';
import Facebook from "./assets/facebook-square-brands.svg";
import Instagram from "./assets/instagram-square-brands.svg";
import styled from "styled-components";
import './head.css';
import Zoom from 'react-reveal/Zoom';

const Icons = styled.div`
textAlign:"center"
  display: flex;
  margin-left: auto;
  margin-right: auto;
  a {
    &:hover {
      img {
        filter: invert(20%) sepia(100%) saturate(500%) hue-rotate(580deg)
          brightness(100%) contrast(97%);
      }
    }
    &:not(:last-child) {
      margin-right: 2rem;
    }
    img {
      width: 3rem;
      height: 3rem;
    }
  }
`;
const Button = styled.button`
  background-color: var(--purple);
  padding: 1.5rem 1.5rem;
  border-radius: 20px;
  color: var(--white);
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    transform: scale(1.3);
  }
  &:focus {
    transform: scale(0.9);
  }
  @media only Screen and (max-width: 40em) {
    font-size:0.8rem;
    &:hover {
      transform: none;
    }
    &:focus {
      transform: none;
    }
  }
`;

const Head = () => (   
  <div style={{  marginTop:"-15px"}} className='h'>








  <div  className="gpt3__header section__padding" id="home">
    <div className="gpt3__header-content">
      <br/>  

      <div  className="hedertex" >




  </div>

  

    </div>

    <div style={{borderRadius:"110px",textAlign:"center",marginBottom:"225px" }} className="gpt3__header-image">
    <Typist>
    <Zoom >

    <img className='im' style={{textAlign:"center",zIndex:"-1" }} src={ai}  />
  
    </Zoom>
    <h1 style={{textAlign:"center",zIndex:"-1" ,fontFamily:"cursive"}} className='YU' >  S.G one ניקיון ואחזקות</h1>




</Typist>

<br/>



      <Zoom bottom>



<a  href="tel:050-6978865" >
      <Button style={{textAlign:"center" }} >התקשרו אלינו  </Button>
    </a>

    </Zoom>
    </div>
    <div>


 
    </div>
  </div>
  <head><script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4942463590341754"
     crossorigin="anonymous"></script></head>
  </div>
);

export default Head;



////<Icons>
///<a target="_blank" a href="https://m.facebook.com/profile.php?id=100063709712322&_rdr/">
  ///{" "}
 /// <img style={{textAlign:"center",zIndex:"-1"}}  src={Facebook} alt="Facebook" />
///</a>


////</Icons>
///<Zoom bottom>
////<div class="icon facebook">
/////<img className='im22' style={{textAlign:"center",zIndex:"-1"}} src={ai2}  />




     ////// </div>

      /////</Zoom>