import React from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { deviceSize } from "../components/responsive";
import TopSectionBackgroundImg from "../img/3.jpeg";
import { useState } from 'react'
import emailjs from 'emailjs-com'

import './topSection.css';

const TopSectionContainer = styled.div`
  width: 100%;
  height: 450px;
  background: url(${TopSectionBackgroundImg}) no-repeat;
  background-position: 0px -150px;
  background-size: cover;


  
  @media only Screen and (max-width: 40em) {
    height: 440px;

    &:hover {
      transform: none;
    }
    &:focus {
      transform: none;
    }
  }
`;



const TopSec = styled.div`
  width: auto;
  height: auto;
  text-align: center;
  margin-left: auto;
  margin-right: auto;

  align-items: center;


  
  @media only Screen and (max-width: 40em) {



  }
`;

const Title = styled.h1`
color: '#0a0b10';
font-size: calc(1rem + 0.9vw);
text-align: center;
position: relative;
&::before {
  content: "";
  height: 1px;
  width: 50%;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0.5rem);
  /* or 100px */
  border-bottom: 2px solid var(--purple);
  }
  @media only Screen and (max-width: 40em) {
    text-align: center;

    font-size: 20px;
    &:hover {
      transform: none;
    }
    &:focus {
      transform: none;
    }
  }
`;
const BackgroundFilter = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(38, 70, 83, 0.9);
  display: flex;
  flex-direction: column;
`;

const TopSectionInnerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;



const LogoContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media screen and (max-width: ${deviceSize.mobile}px) {
    align-items: center;
  }
`;

const SloganText = styled.h3`
  margin: 0;
  line-height: 1.4;
  color: #fff;
  font-weight: 500;
  font-size: 25px;


  @media only Screen and (max-width: 40em) {
    &:hover {
      transform: none;
    }
    &:focus {
      transform: none;
    }
  }
`;

const Button = styled.button`
  background-color: var(--purple);
  padding: 0.5rem 1rem;
  border-radius: 20px;
  color: var(--white);
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    transform: scale(1.1);
  }
  &:focus {
    transform: scale(0.9);
  }
  @media only Screen and (max-width: 40em) {
    font-size: 0.7rem;
    &:hover {
      transform: none;
    }
    &:focus {
      transform: none;
    }
  }
`;





const initialState = {
  name: '',
  email: '',
  message: '',
}







export function TopSection(props) {
  const [{ name, email, message }, setState] = useState(initialState)

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }
  const clearState = () => setState({ ...initialState })

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(name, email, message)
    emailjs
      .sendForm(
        'service_vegf8ui', 'template_br2e9eh', e.target, 'user_7XjWzaNdKiGR2BG0wBiLt'
      )
      .then(
        (result) => {
          console.log(result.text)
          clearState()

          alert('הודעה נשלחה בהצלחה , ניצור קשר בהקדם! תודה ')
          clearState()
        },
        (error) => {
          console.log(error.text)
          alert('הודעה לא נשלחה , נסה שנית')

        }
      )
  }

  const { children } = props;

  const isMobile = useMediaQuery({ maxWidth: deviceSize.mobile });

  return (
    <TopSectionContainer>

      <BackgroundFilter>

        {children}
        <TopSectionInnerContainer>


<TopSec>
<div class="container">

          <form  name='sentMessage' validate onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-6'>
                    <div  className='form-group'>
                      <input style={{textAlign:"right"}}
                        type='phone'
                        id='phone'
                        name='phone'
                        className='form-control'
                        placeholder='טלפון'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input style={{textAlign:"right"}}
                        type='text'
                        id='name'
                        name='name'
                        className='form-control'
                        placeholder='שם'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <textarea style={{textAlign:"right"}}
                    name='message'
                    id='message'
                    className='form-control'
                    rows='4'
                    placeholder='הודעה'
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className='help-block text-danger'></p>
                </div>
                <div id='success'></div>
                <Button  type="submit" className="btn btn-info"> שלח</Button>

              </form>
              </div>

              </TopSec>
                <SloganText >





          </SloganText>
        </TopSectionInnerContainer>
      </BackgroundFilter>
    </TopSectionContainer>
  );
}
