import React from 'react';
import lo from '../assets/1.png'
import lo2 from '../assets/2.png'
import lo3 from '../assets/3.png'
import lo4 from '../assets/4.png'
import lo5 from '../assets/5.png'
import lo6 from '../assets/6.png'
// Bootstrap
import {Container, Card, Accordion, Button} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from "styled-components";
import './questions.css';



const Styleapp2 = styled.div`

width: auto;
height: auto;


@media only Screen and (max-width: 40em) {
  font-size: 10px;
  text-align: center;


  }
}


`








const Title = styled.h1`
color: '#0a0b10';
font-size: calc(1rem + 1.5vw);
text-align: center;
position: relative;
&::before {
  content: "";
  height: 1px;
  width: 50%;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0.5rem);
  /* or 100px */
  border-bottom: 2px solid var(--purple);
  }
`;






export default ({reference}) => (
    <Styleapp2 >

    <section    ref={reference}>

        <Container>
<br/><br/>


<section  style={{textAlign:"center"}} className="page-section" >
    <div  className="container">
      <div className="row">
        <div className="col-lg-12 text-center">
        <Title  style={{textAlign:"center"}} > : השירותים שלנו</Title>
        <br/>    <br/><br/>
        </div>
        
      </div>
      <div id="questions" className="row text-center">
        <div className="col-md-4">
          <span className="fa-stack fa-4x">
            <i className="fa fa-circle fa-stack-2x text-primary"></i>
            <i ><img className="im123" src={lo3} alt="" /></i>
          </span>
          <h4 className="service-heading">
עובדי ניקיון וצוותי ניקיון  </h4>          <br/>

          <p  > היכנסו למשרד בכל יום עם אווירה נעימה ומשרד נקי תוך ידיעה שאתם עובדים מול החברה המקצועית ביותר ובמחירים המשתלמים ביותר בכל הקשור לניקיון משרדים , צוותי הניקיון זמנים בכל שעות היממה לפי צרכיו של הלקוח</p>

        </div>
        <div className="col-md-4">
          <span className="fa-stack fa-4x">
            <i className="fa fa-circle fa-stack-2x text-primary"></i>
            <i ><img className="im123" src={lo2} alt="" /></i>
          </span>
          <br/><br/><br/>
          <h4 className="service-heading">קרצוף פוליש לרצפה ופוליש ווקס </h4>          <br/>

          <p >אין צורך לדאוג לגבי נראות הרצפה – הפוליש שלנו הוא ברמה הגבוה ביותר ואנחנו נדאג שלא תוכלו לזהות את הרצפה לאחר הטיפול. המחיר שלנו הוא גם האטרקטיבי ביותר – ככה זה שאתם עובדים עם החברה המובילה ביותר</p>
        </div>
        <div className="col-md-4">
          <span className="fa-stack fa-4x">
            <i className="fa fa-circle fa-stack-2x text-primary">            </i>
            <i ><img className="im123" src={lo} alt="" /></i>
          </span>
          <h4 className="service-heading"> ניקיון ואחזקות נכסים מסחריים </h4>          <br/>

          <p >ניקיון לאחר שיפוץ, ניקיון לפני איכלוס,  ניקיון משרדים, ניקיון בנייני משרדים ומסחריים,  שימוש בחומרים המובילים בשוק, שימוש במכשור מתקדם, מחירים הוגנים, אחריות מלאה על כל עבודה</p>
        </div>
      </div>
      <br/>
      <div className="row text-center">
        <div className="col-md-4">
          <span className="fa-stack fa-4x">
            <i className="fa fa-circle fa-stack-2x text-primary"></i>
            <i ><img className="im123" src={lo6} alt="" /></i>
          </span>
          <h4 className="service-heading"> שירותי הנדימן לנכסים מסרחיים ומשרדים </h4>          <br/>

          <p > כל תיקון שצריך בבניין מסחרי / עסקי /  משרדי - אנחנו כאן לתיקונים קטנים ובינוניים וגדולים עם ידי זהב ויכולת לחדש ולתקן כמעט כל דבר שצריך. מקצועיות ואמינות. מחירים אטרקטיביים. יחס אישי של מנהל העסק. עמידה בלוחות זמנים. שירותים: ניסיון ומקצועיות, אמינות ודיוק, יחס אישי</p>
        </div>
        <div className="col-md-4">
          <span className="fa-stack fa-4x">
            <i className="fa fa-circle fa-stack-2x text-primary"></i>
            <i ><img className="im123" src={lo5} alt="" /></i>
          </span>
          <h4 className="service-heading">שירותי ניקיון  לשטחים פתוחים וציבוריים</h4>
          <br/>
          <p >חברתנו מספקת שירותי חצרנות ע"י חצרנים מקצועיים אשר מתמחים בניקיון שטחים פתוחים ובשימוש של מכונות ייעודיות לכל שטח בהתאמה</p>

        </div>
        <div className="col-md-4">
          <span className="fa-stack fa-4x">
            <i className="fa fa-circle fa-stack-2x text-primary">            </i>
            <i ><img className="im123" src={lo4} alt="" /></i>
          </span>
          <h4 className="service-heading"> שירותי אחזקה באופן שוטף לנכסי מסחר / ציבוריי   </h4>      
          <p > חברתנו מספקת אב בית לפי צרכיו של הלקוח , טיפול במערכות הבניין השונות ותחזוקת כל בניין לפי צרכיו הייחודיים, אחזקת שוטפת של כל מערכות הבניין, משאבות, גנרטור-הפעלה חודשית וטיפול רבעוני, ספרינקלרים, רכזת כיבוי אש, מעליות, מערכת סולארית, ניקוי וחיטוי מאגרי מים, מפוחים, ניקיון המבנה, גינון ועוד
שירותי האחזקה כוללים בין היתר-החלפת נורות וטיפול בחשבונות מים גבוהים </p>
        </div>
      </div>
    </div>
  </section>





        </Container>
    </section>
    </Styleapp2 >
)