import { GlobalStyle } from "./globalStyles";
import { lazy, Suspense } from "react";
////import Screen  from './components/Screen';
import Head  from './Head';
import { useState, useEffect } from "react";
import  Office  from './components/questions';
import { About } from './components/About';
import { TopSection } from './components/topSection';
import { TopSection3 } from './components/topSection3';
import { Icons } from './components/Icons';
import { Contact } from "./Sections/contact";
import styled from 'styled-components';
import { AccessibilityWidget } from 'react-accessibility';
import { FloatingWhatsApp } from 'react-floating-whatsapp-button'
import 'react-floating-whatsapp-button/dist/index.css'

import "./App.css";

const Styleapp = styled.div`

width: auto;
height: auto;



`
const Home = lazy(() => import("./Pages/Home"));
const Header = lazy(() => import("./components/Header/index"));
const ScrollToTop = lazy(() => import("./components/ScrollToTop/index"));

function App() {


  const [landingPageData, setLandingPageData] = useState({});
                                  


  return (
    <>
    <Styleapp>
      <Suspense fallback={null}>
      <ScrollToTop />
      <Icons />
      <FloatingWhatsApp />

      <GlobalStyle />
        <Header data={landingPageData.Features} />

        <Head data={landingPageData.Features} />

        <Office data={landingPageData.Features} />

        <Home data={landingPageData.Features} />

        <About data={landingPageData.Features} >
</About>

<TopSection data={landingPageData.Features} >
</TopSection>
<TopSection3 data={landingPageData.Features} >
</TopSection3>
<Contact data={landingPageData.Features} />
          
      </Suspense>

      </Styleapp>
    </>
  );
}

export default App;




///<TopSection>
///</TopSection>



/// background:url(${banner});
///background-repeat: no-repeat;
///background-attachment: fixed;
///background-position: center;
///background-size: cover;



 
        
///<About>
///</About>

///<TopSection>
///</TopSection>


///<Contact data={landingPageData.Features} />