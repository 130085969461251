
import React from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import a2 from '../img/facebook.png';
import a3 from '../img/whatsapp.png';
import a6 from '../img/amksoanim.png';


import './topSection.css';

const TopSectionContainer = styled.div`

position: fixed;
z-index: 498;
margin-top: 200px;
margin-left: 15px;

`;

const BackgroundFilter = styled.div`

`;

const TopSectionInnerContainer = styled.div`

`;




const SloganText2 = styled.h3`

`;







export function Icons(props) {





  return (
    <TopSectionContainer >




<div>

<div class="wrapper">

<div class="icon facebook">
  <div  class="tooltip">פייסבוק</div>
<span><i class="fab fa-facebook-f"></i></span>
<a target="_blank" a href="https://m.facebook.com/profile.php?id=100063709712322&_rdr/"><img  src={a2} alt="3" />
</a>
</div>
<div    class="icon twitter">
  <div class="tooltip">הודעה</div>
  <span><i   class="fab fa-twitter"></i></span>
  <a target="_blank"    a href="https://wa.me/972506978865"  > <img  src={a3} alt="3" /> </a>
</div>
<div class="icon youtube">
    <div class="tooltip"> מידרג</div>
    <span><i class="fab fa-youtube"></i></span>
    <a  target="_blank" a href="https://www.midrag.co.il/SpCard/Sp/81735?areaId=4&serviceId=1249&sortByCategory=569&isGeneric=false" > <img  src={a6} alt="3" />   </a>

  </div>



</div>

</div>



      
    </TopSectionContainer>
  );
}








































