import { useState } from 'react'
import emailjs from 'emailjs-com'
import './Contact.css'







export const Contact = (props) => {

  return (
    <div class="container">

    <div style={{textAlign:"center" ,marginTop:'auto',marginBottom:"-23px",height: "110px"}} >
   

      
             
   <ul>

            
         <p>
           &copy; 2022 אתר זה נבנה על ידי {' '}
           <a  target="blank" href='https://www.haimsite.co.il/' rel='nofollow'>
             haimsite.co.il
           </a>
         </p>
         </ul>
        </div>
        <head><script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4942463590341754"
     crossorigin="anonymous"></script></head>
                 </div>

     
    
 
  
  )
}


