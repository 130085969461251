import React from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { Marginer } from "../components/marginer";
import { deviceSize } from "../components/responsive";
import TopSectionBackgroundImg from "../img/2.jpeg";
import Flip from 'react-reveal/Flip';
import ai from '../img/AODG26844.png';
import Zoom from 'react-reveal/Zoom';


const Title = styled.h1`
color: '#0a0b10';
font-size: calc(1rem + 1.5vw);
text-align: center;
position: relative;
&::before {
  content: "";
  height: 1px;
  width: 15%;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0.5rem);
  /* or 100px */
  border-bottom: 2px solid var(--purple);
  }
  @media only Screen and (max-width: 40em) {
    text-align: center;

    font-size: 20px;
    &:hover {
      transform: none;
    }
    &:focus {
      transform: none;
    }
  }
`;





const TopSectionContainer = styled.div`
  width: 100%;
  height: 400px;
  background: url(${TopSectionBackgroundImg}) no-repeat;
  background-position: 0px -150px;
  background-size: center;



  @media only Screen and (max-width: 40em) {
    text-align: center;
    align-items: center;

    font-size: 10px;

  }
`;

const BackgroundFilter = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(38, 70, 83, 0.9);
  display: flex;
  flex-direction: column;
`;

const TopSectionInnerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

const StandoutImage = styled.div`
  width: 54em;
  height: 30em;

  img {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: ${deviceSize.mobile}px) {
    width: 14em;
  height: 10em;
  }
`;

const LogoContainer = styled.div`
width: 50%;
height: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media screen and (max-width: ${deviceSize.mobile}px) {
    width: auto;
height: auto;
  }
`;

const SloganText1 = styled.h4`
text-align: right;
  color: #fff;
  font-weight: 100;
  font-size: 25px;
  @media screen and (max-width: ${deviceSize.mobile}px) {
    font-size: 14px;
  }
`;

export function About(props) {
  const { children } = props;

  const isMobile = useMediaQuery({ maxWidth: deviceSize.mobile });

  return (
    <TopSectionContainer>

      <BackgroundFilter>
        {children}

        <TopSectionInnerContainer>
         
            <Marginer direction="vertical" margin={8} />
            <div class="container">

            <Flip>


       
          <SloganText1> 
            
          <p >בהנהלת שי גדפור בעל ניסיון וותק בתחום, מתמחה ומבצע את כל עבודות הנקיון עם צוות מסור ומקצועי</p>  
          <p>ניקיון לאחר שיפוץ, ניקיון לפני איכלוס,ניקיון משרדים,ניקיון בנייני מסחר וציבורי בלבד,שימוש במכשור</p> 
          <p>  מתקדם, מחירים הוגנים, אחריות מלאה על כל עבודה, עמידה בלוחות זמנים, שקיפות מלאה</p> 
            <p>       מתחילת העבודה ועד לסיומה, ליווי וייעוץ מלא בהתאמה לצרכי הלקוח ותקציבו         </p>
            

            </SloganText1> 



      
          </Flip>
          </div>

          <Zoom >
 <LogoContainer>

 <img  src={ai}  />
 </LogoContainer>


</Zoom>
        </TopSectionInnerContainer>

      </BackgroundFilter>
      <Title style={{textAlign:"center",color:"white"}} > :   השאירו פרטים ונחזור אליכם</Title>

    </TopSectionContainer>
  );
}
